<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon
        name="arrow-left"
        @click="
          step == 1 ? $fun.routerToPage('/user/accountsetting') : (step -= 1)
        "
      />
    </template>
    <template #default>
      <span>
        {{ $fanyi("注销账户") }}
      </span>
    </template>
    <!--设置内容选项区域 内容区域 -->
  </headBackBar>

  <!-- 内容区域 -->
  <div class="Content" v-if="step == 1">
    <h2>
      {{
        $fanyi(
          "敬请您注意并知悉，当您注销账号后，将（可能）产生包括但不限于如下后果："
        )
      }}
    </h2>
    <h3>
      {{
        $fanyi(
          "1.注销账号后，您将无法登录、使用该账号及账号原验证手机或邮箱登录RAKUMART相关产品与服务，即您将无法再以此账号登录、使用RAKUMART相关产品与服务。"
        )
      }}
    </h3>
    <h3>
      {{
        $fanyi(
          "2.注销账号后，您曾通过该账号登录、使用的RAKUMART相关产品与服务下的所有内容、信息、数据、记录将会被全部删除或匿名化处理（但国家法律法规、规章、规范性文件，政府的政策、命令等另有要求或为履行我们的合规义务的除外），您也无法再检索、访问、获取、继续使用和找回这些信息或数据，包括但不限于："
        )
      }}
    </h3>
    <p>{{ $fanyi("(1)该账号下的您自主设置的个人资料（如：头像、昵称等);") }}</p>
    <p class="magbottom">
      {{
        $fanyi("(2)其他与该账号相关的内容、信息、数据、历史记录或服务记录等。")
      }}
    </p>
    <h3>
      {{
        $fanyi(
          "3.注销账号后，您在使用RAKUMART产品与服务期间产生的账号内资产和虚拟权益将视为自动放弃，无法予以退还或退换，包括但不限于："
        )
      }}
    </h3>
    <p>
      {{
        $fanyi(
          "(1)您在RAKUMART产品与服务中的付费会员、会员等级等各类身份权益、积分、金币等；"
        )
      }}
    </p>
    <p class="magbottom">
      {{ $fanyi(" (2)其他已产生但未消耗完毕的权益及资产或未来的预期收益。") }}
    </p>
    <h3>
      {{
        $fanyi(
          "4.账号注销具有不可逆转和不可撤销性，账号一旦注销完成，将无法恢复。如果您在注销后以相同手机号码或相同电子邮箱再次注册账号，将会注册失败，之前注册过的邮箱与手机号不能再次用来进行注册。"
        )
      }}
    </h3>
    <h3>
      {{
        $fanyi(
          "5.账号一旦注销，您将不再享有您与我们签署的相关用户协议、其他权利义务性文件中约定的适用于注册用户的权利，但法律法规、规章、规范性文件，政府的政策、命令等另有要求或用户服务协议中另有规定的除外。"
        )
      }}
    </h3>
    <div class="understand" @click="step = 2">
      {{ $fanyi("我已了解，继续进行") }}
    </div>
    <div class="cancel" @click="$fun.routerToPage('/user/accountsetting')">
      {{ $fanyi("取消") }}
    </div>
  </div>
  <div class="Content" v-if="step == 2">
    <div class="img-box">
      <img src="../../../../assets/user-img/usersetting/sanjiao.png" alt="" />
    </div>
    <div class="text">
      <h3>{{ $fanyi("请您注意") }}</h3>
      <p>{{ $fanyi("注销成功之后不可恢复,请谨慎操作。") }}</p>
    </div>
    <!-- 密码区域 -->
    <div class="password_box">
      <p>{{ $fanyi("由于您正在进行敏感操作，请输入您的登录密码进行确认") }}</p>
      <van-field v-model="password" :placeholder="$fanyi('请输入密码')" />
    </div>
    <!-- 销毁 -->
    <div class="destroy" @click="destroy">{{ $fanyi("立即注销") }}</div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();
console.log(proxy.$store.state);

const step = ref(1);
const password = ref("");
const destroy = async () => {
  if (password.value == "") {
    Toast(proxy.$fanyi("请输入密码"));
  } else {
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
    });
    const res = await proxy.$api.unsetAccount({ password: password.value });
    if (res.code !== 0) {
      Toast.clear();
      Toast.fail(proxy.$fanyi(res.msg));
      return;
    }
    Toast.clear();
    Toast.success(proxy.$fanyi("注销成功"));
    localStorage.removeItem("japan_user_token");
    proxy.$store.commit("userData", null);
    proxy.$store.commit("setcartCount", 0);
    proxy.$store.commit("outUser");
    proxy.$fun.routerToPage("/");
  }
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

.Content {
  width: 750px;
  height: 1400px;
  background: #f6f6f6;
  background: #f6f6f6;
  padding: 0 30px;
  padding-top: 47px;

  h2 {
    font-size: 30px;

    font-weight: 600;
    color: #000000;
    margin-bottom: 43px;
    line-height: 45px;
  }

  h3 {
    font-size: 24px;

    font-weight: 600;
    color: #000000;
    margin-bottom: 25px;
    line-height: 36px;
  }

  p {
    font-size: 24px;

    font-weight: 400;
    line-height: 36px;
    color: #000000;
  }

  .magbottom {
    margin-bottom: 35px;
  }

  :deep().van-cell {
    background-color: transparent;
    font-size: 28px !important;

    input {
      font-size: 28px !important;
      padding-left: 25px;
    }
  }

  .understand {
    width: 690px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    font-size: 28px;

    font-weight: 400;
    color: #ffffff;
    margin-top: 80px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cancel {
    width: 691px;
    height: 71px;
    font-size: 28px;

    font-weight: 400;
    color: #000000;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    align-items: center;
  }

  .img-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 51px;

    margin-bottom: 44px;

    img {
      width: 96px;
      height: 85px;
    }
  }

  .text {
    width: 100%;

    h3 {
      font-size: 30px;

      font-weight: 600;
      color: #000000;
      display: flex;
      justify-content: center;
      margin-bottom: 26px;
    }

    p {
      font-size: 28px;

      font-weight: 400;
      color: #000000;
      margin-bottom: 51px;
      display: flex;
      justify-content: center;
    }
  }

  .password_box {
    p {
      font-size: 24px;

      font-weight: 400;
      color: #999999;
      margin-bottom: 8px;
      padding-left: 30px;
    }

    :deep().van-cell {
      background-color: transparent;
      font-size: 28px !important;
    }

    input {
      width: 630px;
      height: 70px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      font-size: 28px !important;

      font-weight: 400;
      border-radius: 6px;
    }
  }

  .destroy {
    margin: auto;
    margin-top: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 630px;
    font-size: 28px;

    font-weight: 400;
    color: #ffffff;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
  }
}
</style>
